









import { Component } from 'vue-property-decorator'

import Vue from 'vue'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component({
  name: 'CertificatesListModuleForm'
})
export class CertificatesListModuleForm extends Vue {
}

export default CertificatesListModuleForm
